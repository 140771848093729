import React, { useState, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  DataGridPro,
  gridClasses,
  GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
  GridToolbarQuickFilter,
} from '@mui/x-data-grid-pro';
import { Box, Card, ButtonGroup, Button } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import { DetailDropdownPanel, DetailPanelIcon } from './index';
import { formatCurrency, formatDate } from '../../utils/formatting';
import { useNavigate } from 'react-router-dom';
import noCurrentOffers from '../../assets/noCurrentOffers.png';
import { PageTemplate } from '../../components/PageTemplate';

function CellContent({ value }) {
  return <div>{value?.length < 25 ? value : value?.slice(0, 25)}</div>;
}

export const Table = ({ tabValue, pipelineData }) => {
  const navigate = useNavigate();
  !pipelineData && navigate('/oops');
  const [rows, setRows] = useState([]);
  const [offerStatus, setOfferStatus] = useState('Serviced');
  const [filteredRows, setFilteredRows] = useState([]);
  const [columnDetails, setColumnDetails] = useState([]);
  const { mode } = useSelector(({ mode }) => mode);
  const backgroundColor =
    mode === 'light' ? 'rgba(255, 255, 255, 1)' : '#252525';
  const hasData = filteredRows.length > 0;
  let noRowsText = {
    title: 'No Current Offers',
    description: "It looks like you don't have any KEEP offers at this time.",
  };

  useEffect(() => {
    if (pipelineData.isAccountExecutiveInd) {
      setOfferStatus('Non-Serviced');
    }
  }, [pipelineData.isAccountExecutiveInd]);

  switch (tabValue) {
    case 'Application in Progress':
      noRowsText.title = 'No Current Applications In Progress';
      noRowsText.description =
        "It looks like you don't have any KEEP applications in progress at this time.";
      break;
    case 'Application Complete':
      noRowsText.title = 'No Current Completed Applications';
      noRowsText.description =
        "It looks like you don't have any completed KEEP applications at this time.";
      break;
  }

  const filterRows = (offerStatus, loanStatus) => {
    return rows.filter(
      (row) =>
        offerStatus.includes(row.offerStatus) && row.loanStatus === loanStatus,
    );
  };

  const filterByTab = () => {
    if (rows) {
      switch (tabValue) {
        case 'Application in Progress':
          setFilteredRows(
            filterRows(
              ['Accessed', 'In Progress', 'Accepted', 'Pending Consent'],
              offerStatus === 'Serviced' ? 'ACTIVE' : 'SOLD',
            ),
          );
          break;
        case 'Application Complete':
          setFilteredRows(
            filterRows(
              ['Submitted'],
              offerStatus === 'Serviced' ? 'ACTIVE' : 'SOLD',
            ),
          );
          break;
        case 'Offers':
          setFilteredRows(
            filterRows(
              ['Sent'],
              offerStatus === 'Serviced' ? 'ACTIVE' : 'SOLD',
            ),
          );
          break;
        default:
          setFilteredRows(rows);
          break;
      }
    } else {
      setFilteredRows(rows);
    }
  };

  const getColumnDetails = (tabValue) => {
    let columns = [];

    if (tabValue === 'Application Complete') {
      columns.push({
        field: 'newLoanNumber',
        headerClassName: 'tableHeader',
        headerName: 'New Loan Number',
        disableColumnPinning: true,
        disableReorder: true,
        flex: 1,
        hideable: false,
      });
    }

    columns.push(
      {
        field: 'originalLoanNumber',
        headerClassName: 'tableHeader',
        headerName: 'Original Loan Number',
        disableColumnPinning: true,
        disableReorder: true,
        flex: 1,
        hideable: false,
      },
      {
        field: 'primaryBorrowerName',
        headerClassName: 'tableHeader',
        headerName: 'Borrower',
        disableColumnPinning: true,
        disableReorder: true,
        flex: 1,
        renderCell: (params) => <CellContent {...params} />,
        hideable: false,
      },
    );

    if (tabValue !== 'Action Required') {
      columns.push({
        field: 'subjectPropertyAddressLine1',
        headerClassName: 'tableHeader',
        headerName: 'Subject Property',
        disableColumnPinning: true,
        disableReorder: true,
        flex: 1,
        renderCell: (params) => <CellContent {...params} />,
        hideable: false,
      });
    }

    if (offerStatus === 'Serviced') {
      columns.push({
        field: 'originalPrincipalInterestAmount',
        headerClassName: 'tableHeader',
        headerName: 'Current P & I',
        disableColumnPinning: true,
        disableReorder: true,
        flex: 1,
        valueFormatter: (params) => {
          return formatCurrency(params.value);
        },
        hideable: false,
      });
    }

    columns.push({
      field: 'newPrincipalInterestAmount',
      headerClassName: 'tableHeader',
      disableColumnPinning: true,
      disableReorder: true,
      headerName: 'Est. New P & I',
      flex: 1,
      valueFormatter: (params) => {
        return formatCurrency(params.value);
      },
      hideable: false,
    });

    if (tabValue === 'Offers' || tabValue === 'Application in Progress') {
      columns.push({
        field: 'estimatedMonthlySavings',
        headerClassName: 'tableHeader',
        headerName: 'Est. Monthly Savings',
        disableColumnPinning: true,
        disableReorder: true,
        flex: 1,
        valueGetter: (params) => {
          return formatCurrency(params.value);
        },
        hideable: false,
      });
    }

    switch (tabValue) {
      case 'Offers':
        columns.push({
          field: 'offerDate',
          headerClassName: 'tableHeader',
          headerName: 'Offer Date',
          disableColumnPinning: true,
          disableReorder: true,
          flex: 1,
          valueFormatter: (params) => {
            return formatDate(params.value);
          },
          hideable: false,
        });
        break;
      case 'Application in Progress':
        columns.push({
          field: 'applicationStartDate',
          headerClassName: 'tableHeader',
          headerName: 'Date Started',
          disableColumnPinning: true,
          disableReorder: true,
          flex: 1,
          valueFormatter: (params) => {
            return formatDate(params.value);
          },
          hideable: false,
        });
        break;
      case 'Application Complete':
      case 'Action Required':
        columns.push({
          field: 'applicationCompletedDate',
          headerClassName: 'tableHeader',
          headerName: 'Date Completed',
          disableColumnPinning: true,
          disableReorder: true,
          flex: 1,
          valueFormatter: (params) => {
            return formatDate(params.value);
          },
          hideable: false,
        });
        break;
      default:
        break;
    }

    columns.push({
      field: 'originator',
      headerClassName: 'tableHeader',
      headerName: 'Originator',
      disableColumnPinning: true,
      disableReorder: true,
      flex: 0.75,
      renderCell: (params) => <CellContent {...params} />,
      hideable: false,
    });

    if (pipelineData.isAccountExecutiveInd) {
      columns.push({
        field: 'brokerUsername',
        headerClassName: 'tableHeader',
        headerName: 'Broker ID',
        disableColumnPinning: true,
        disableReorder: true,
        flex: 0.75,
        renderCell: (params) => <CellContent {...params} />,
        hideable: false,
      });
    }

    columns.push({
      ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
      headerClassName: 'tableHeader',
      headerName: '',
      cellClassName: 'dropdownIcon',
      disableReorder: true,
      flex: 0.25,
      align: 'center',
      type: 'string',
      disableColumnPinning: true,
      hideable: false,
      renderCell: (params) => {
        return <DetailPanelIcon />;
      },
    });

    return columns;
  };

  useEffect(() => {
    const getDataToPopulate = async () => {
      setRows(await pipelineData?.offers);
      filterByTab();
    };

    getDataToPopulate();
    setColumnDetails(getColumnDetails(tabValue));
  }, [offerStatus, tabValue, rows]);

  const getDetailPanelContent = useCallback(
    ({ row }) => <DetailDropdownPanel row={row} />,
    [],
  );

  const getDetailPanelHeight = useCallback(() => 'auto', []);

  const StripedDataGridPro = styled(DataGridPro)(({ theme }) => ({
    [`& .${gridClasses.row}.evenRow`]: {
      backgroundColor: theme.palette.action.selected,
      '&:hover, &.Mui-hovered': {
        backgroundColor: alpha(theme.palette.action.hover, 0.1),
        '@media (hover: none)': {
          backgroundColor: 'transparent',
        },
      },
    },
    '&.Mui-selected': {
      backgroundColor: alpha(
        theme.palette.primary.main,
        0.2 + theme.palette.action.selectedOpacity,
      ),
      '&:hover, &.Mui-hovered': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          0.2 +
            theme.palette.action.selectedOpacity +
            theme.palette.action.hoverOpacity,
        ),
      },
    },
    '& > .MuiDataGrid-columnSeparator, .MuiDataGrid-columnSeparator--resizable, .MuiDataGrid-columnSeparator--sideRight':
      {
        visibility: 'hidden',
      },
    '& .MuiDataGrid-columnHeader:hover .MuiDataGrid-columnSeparator': {
      visibility: 'visible',
    },
    '& .MuiFilledInput-input': {
      paddingTop: '8px',
      paddingLeft: '4px',
    },
  }));

  const StyledGridOverlay = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '20px',
    height: '100%',
    pointerEvents: 'none',
  }));

  const SearchAndFilterToolbar = () => {
    return (
      <Box
        sx={{
          p: 0.5,
          pb: 0,
          padding: '20px 10px',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        {!pipelineData.isAccountExecutiveInd && (
          <ButtonGroup variant="outlined" sx={{ height: '40px' }}>
            <Button
              id="servicedFilter"
              onClick={() => {
                setOfferStatus('Serviced');
              }}
              variant={offerStatus === 'Serviced' ? 'contained' : 'outlined'}
            >
              Automatic Send
            </Button>
            <Button
              id="manualFilter"
              onClick={() => {
                setOfferStatus('Manual');
              }}
              variant={offerStatus === 'Manual' ? 'contained' : 'outlined'}
            >
              Manual Send
            </Button>
          </ButtonGroup>
        )}
        <GridToolbarQuickFilter
          variant="outlined"
          sx={{
            '& .MuiInputBase-root': { height: '40px' },
          }}
        />
      </Box>
    );
  };

  const CustomNoRowsOverlay = () => {
    return (
      <StyledGridOverlay>
        <PageTemplate
          title={noRowsText.title}
          descText={noRowsText.description}
          img={noCurrentOffers}
          imgDesc={'image for no current offers'}
          sx={{
            marginTop: 0,
          }}
        />
      </StyledGridOverlay>
    );
  };

  return (
    <Box
      sx={{
        width: '80%',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        margin: 'auto',
        marginBottom: '16px',
        minHeight: hasData ? 'auto' : '700px',
      }}
    >
      <Card
        variant="outlined"
        sx={{
          width: '100%',
          height: 'auto',
          backgroundColor: { backgroundColor },
          minHeight: hasData ? 'auto' : '700px',
        }}
      >
        <StripedDataGridPro
          autoHeight
          disableColumnPinning
          rows={filteredRows}
          columns={columnDetails}
          components={{
            Toolbar: SearchAndFilterToolbar,
            NoRowsOverlay: CustomNoRowsOverlay,
          }}
          componentsProps={{
            pagination: {
              labelRowsPerPage: 'Rows Per Page: ',
            },
          }}
          getRowId={(row) => row?.originalLoanNumber + Math.random()}
          pagination={true}
          paginationModel={{ page: 0, pageSize: 10 }}
          pageSizeOptions={[10, 25, 50, 100]}
          getRowClassName={(params) =>
            params.indexRelativeToCurrentPage % 2 === 0 ? 'evenRow' : 'oddRow'
          }
          sx={{
            width: '100%',
            margin: 0,
            padding: 0,
            borderRadius: 0,
            border: 0,
            minHeight: hasData ? 'auto' : '700px',
          }}
          rowHeight={60}
          getDetailPanelContent={getDetailPanelContent}
          getDetailPanelHeight={getDetailPanelHeight}
        />
      </Card>
    </Box>
  );
};

export default Table;
